// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-tsx": () => import("./../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-games-adventure-sheet-landing-tsx": () => import("./../../src/pages/games/adventure-sheet-landing.tsx" /* webpackChunkName: "component---src-pages-games-adventure-sheet-landing-tsx" */),
  "component---src-pages-games-adventure-sheet-tsx": () => import("./../../src/pages/games/adventure-sheet.tsx" /* webpackChunkName: "component---src-pages-games-adventure-sheet-tsx" */),
  "component---src-pages-games-comiquest-tsx": () => import("./../../src/pages/games/comiquest.tsx" /* webpackChunkName: "component---src-pages-games-comiquest-tsx" */),
  "component---src-pages-games-memo-quest-landing-tsx": () => import("./../../src/pages/games/memo-quest-landing.tsx" /* webpackChunkName: "component---src-pages-games-memo-quest-landing-tsx" */),
  "component---src-pages-games-memo-quest-tsx": () => import("./../../src/pages/games/memo-quest.tsx" /* webpackChunkName: "component---src-pages-games-memo-quest-tsx" */),
  "component---src-pages-games-tsx": () => import("./../../src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-presskits-memo-quest-tsx": () => import("./../../src/pages/presskits/memo-quest.tsx" /* webpackChunkName: "component---src-pages-presskits-memo-quest-tsx" */)
}

